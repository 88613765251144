<template>
  <a-row class="payment-method-container">
    <a-row :span="24">
      <a-col style="width: 50%;">
        <div class="payment-method-main-title">
          Metode Pengiriman
        </div>
      </a-col>
      <a-col>
        <a-radio-group
          v-model="selectedShippingType"
          class="w-100 list-payment-method"
        >
          <div
            :class="`box-payment-method${selectedShippingType === 'pickup' ? '__active' : ''}`"
            :style="{
              width: isMobileView ? '50%' : '180px'
            }"
            @click.prevent="() => {
              $emit('emitSelectedShippingType', 'pickup')
              $emit('emitIsDropshipper', false)
            }"
          >
            <a-radio :value="'pickup'" class="default-checkbox"/>
            <div>
              <b>Ambil Sendiri</b>
            </div>
          </div>
          <div
            :class="`box-payment-method${selectedShippingType === 'shipping' ? '__active' : ''}`"
            :style="{
              width: isMobileView ? '50%' : '180px'
            }"
            @click.prevent="() => {
              $emit('emitSelectedShippingType', 'shipping')
            }"
          >
            <a-radio :value="'shipping'" class="default-checkbox"/>
            <div>
              <b>Dikirim ke Alamat Pengiriman</b>
            </div>
          </div>
        </a-radio-group>
      </a-col>
    </a-row>
  </a-row>
</template>

<script>
export default {
  data: function () {
    return {
    }
  },
  props: {
    selectedShippingType: {
      type: String,
      default: () => 'pickup',
    },
  },
  computed: {
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
  },
}
</script>

<style lang="scss" scoped>
  .payment-method-container{
    margin-bottom: 20px;
  }
 .payment-method-main-title {
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0 10px 0;
  }
  .box-payment-method {
    border: 1px solid var(--product-status-bg-completed);
    border-radius: 6px;
    padding: 8px 16px;
    width: 25%;
    display: flex;
    align-items: center;
    color: var(--biz-primary-20);
    cursor: pointer;
    &:hover {
      border: 1px solid var(--biz-tertiary-100);
    }
    &__active {
      border: 1px solid var(--biz-tertiary-100);
      border-radius: 6px;
      padding: 8px 16px;
      width: 25%;
      display: flex;
      align-items: center;
    cursor: pointer;
    }
  }
  .list-payment-method {
    display: flex;
    gap: 20px;
  }
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--biz-accent-error-toast);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid var(--biz-brand-base);
}
.default-checkbox {
  accent-color: var(--biz-brand);
  color: var(--biz-brand-base);
  transform: scale(1.2);
}
.ant-radio-checked .ant-radio-inner {
  background-color: var(--biz-brand)!important;
  border-color: var(--biz-brand)!important;
}
</style>
