<template>
  <a-modal id="modal-voucher" title="Kupon" :visible="showModal" @cancel="onCloseModal" class="p-3" :footer="null" :maskClosable="false">
    <template v-if="detailVoucher">
      <div slot="modal-header" class="d-flex align-items-center py-2 w-100">
        <div @click="detailVoucher = null" class="m-0 p-0" style="cursor: pointer;">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="12" fill="#000000"/>
          <path d="M10.8248 17.9425C10.7521 17.9068 10.6511 17.8383 10.6019 17.7917C10.4211 17.6218 6.18457 12.6444 6.12589 12.5293C6.0367 12.3621 5.98506 12.1072 6.00384 11.9126C6.0367 11.5508 5.90995 11.7125 8.38382 8.81816C10.2498 6.63644 10.6723 6.15679 10.7779 6.09375C10.961 5.98686 11.252 5.96767 11.4468 6.05538C11.6557 6.14857 11.8059 6.30205 11.8951 6.51858C11.9608 6.68303 11.9679 6.72415 11.9679 6.95986C11.9679 7.4121 12.0054 7.35454 10.6488 8.95794C10.0057 9.7199 9.39543 10.427 9.28981 10.5339C9.18419 10.6436 9.01285 10.7833 8.89784 10.8519L8.69599 10.9752L13.0077 10.9889L17.3193 11.0026L17.4531 11.0657C17.6432 11.1561 17.8287 11.3617 17.9225 11.5864C17.993 11.7509 18 11.792 18 12.003C17.9977 12.1949 17.9883 12.2634 17.9366 12.3922C17.8545 12.6005 17.7043 12.7842 17.5306 12.8883L17.3897 12.976L13.0358 12.9897L8.68191 13.0034L8.90489 13.1405L9.12786 13.2748L10.4798 14.8535C12.0406 16.6734 11.9679 16.5638 11.9679 17.0325C11.9679 17.2764 11.9608 17.323 11.9022 17.4601C11.867 17.545 11.7848 17.6739 11.7214 17.7424C11.5524 17.9288 11.4257 17.9863 11.1699 17.9973C10.9891 18.0055 10.9398 17.9973 10.8248 17.9425Z" fill="white"/>
          </svg>
        </div>
        <div class="ml-3 text-header-modal-detail pb-2">Syarat & Ketentuan</div>
      </div>
      <div class="mb-3 mt-3 p-3 d-flex voucher-container flex-column">
        <div class="d-flex w-100 mb-2" style="height: 120px;">
          <div style="object-fit: cover; position: relative;">
            <VoucherOutline :fill="fixedVoucher(detailVoucher) === 'fixed' ? '#015CA1' : fixedVoucher(detailVoucher) === 'percentage' ? '#F5831F' : '#ABABAB'"/>
            <VoucherFixedIcon class="voucher-icon" v-if="fixedVoucher(detailVoucher) === 'fixed'"/>
            <VoucherPercentageIcon class="voucher-icon" v-else-if="fixedVoucher(detailVoucher) === 'percentage'"/>
          </div>
          <div class="item border p-2 d-flex flex-column" style="width: 85%; height: 114px;">
            <div class="d-flex justify-content-between align-items-start mb-auto">
              <div>
                <div class="title">{{ detailVoucher.name }}</div>
                <div class="desc mt-1"> Jenis voucher:
                  <span class="desc" v-if="fixedVoucher(detailVoucher) === 'fixed'">Min belanja Rp. {{ toCurrency(detailVoucher.min_transaction) }}</span>
                  <span class="desc" v-else>Diskon {{ detailVoucher.value }} %</span>
                </div>
                <div class="expired-date mt">Berlaku sampai {{ getExpiredVoucher(detailVoucher) }}</div>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-start">
              <div class="d-flex align-items-center" style="gap: 0px 3px;" v-if="isCombinedVoucher(detailVoucher)">
                <div class="status">Kombinasi</div>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C2.68636 0 0 2.68636 0 6C0 9.31364 2.68636 12 6 12C9.31364 12 12 9.31364 12 6C12 2.68636 9.31364 0 6 0ZM8.60073 4.98546C8.64862 4.93071 8.68507 4.86695 8.70796 4.79791C8.73084 4.72887 8.73968 4.65595 8.73397 4.58345C8.72825 4.51094 8.70809 4.44031 8.67468 4.37571C8.64126 4.31111 8.59526 4.25384 8.53939 4.20728C8.48352 4.16072 8.41889 4.1258 8.34933 4.10458C8.27976 4.08336 8.20665 4.07627 8.1343 4.08372C8.06195 4.09118 7.99182 4.11303 7.92804 4.14798C7.86426 4.18294 7.80812 4.2303 7.76291 4.28727L5.41745 7.10127L4.20382 5.88709C4.10094 5.78773 3.96316 5.73275 3.82015 5.734C3.67713 5.73524 3.54032 5.7926 3.43919 5.89374C3.33806 5.99487 3.28069 6.13167 3.27945 6.27469C3.27821 6.41771 3.33319 6.55549 3.43255 6.65836L5.06891 8.29473C5.1225 8.34829 5.18667 8.3901 5.25732 8.41749C5.32797 8.44488 5.40354 8.45725 5.47924 8.45381C5.55493 8.45037 5.62908 8.4312 5.69695 8.39752C5.76483 8.36384 5.82494 8.31638 5.87345 8.25818L8.60073 4.98546Z" fill="#2CD397"/>
                </svg>
              </div>
              <div class="d-flex align-items-center" style="gap: 0px 3px;" v-else>
              </div>
            </div>
          </div>
        </div>
        <div class="detail-voucher mb-2">
          <div class="title">Jenis Voucher</div>
          <div class="desc" v-if="fixedVoucher(detailVoucher) === 'fixed'">Min belanja Rp. {{ toCurrency(detailVoucher.min_transaction) }}</div>
          <div class="desc" v-else>Diskon {{ detailVoucher.value }} %</div>
        </div>
        <div class="detail-voucher mb-2" v-if="isCombinedVoucher(detailVoucher)">
          <div class="title">Kombinasi</div>
          <div class="desc">Pengguna voucher ini dapaat dikombinasikan dengan voucher lain</div>
        </div>
        <div class="detail-voucher mb-2">
          <div class="title">Masa Berlaku</div>
          <div class="desc">{{ getExpiredVoucher(detailVoucher) }}</div>
        </div>
        <div class="detail-voucher mb-2">
          <div class="title">Produk</div>
          <div class="desc">Semua Produk</div>
        </div>
        <!-- <div class="detail-voucher mb-2">
          <div class="title">Syarat & Ketentuan</div>
          <div class="desc">-</div>
        </div> -->
      </div>
      <div class="w-100 p-3">
        <a-button
          @click.prevent="() => undefined"
          type="primary"
          block
          class="mt-3 modal-button-submit"
          style="
            border-radius: 12px !important;
          "
        >
          Gunakan
        </a-button>
      </div>
    </template>
    <template v-else>
      <div slot="modal-header" class="d-flex align-items-center justify-content-between py-2 w-100">
        <span class="text-header-modal mb-0">Kupon</span>
        <span @click="onCloseModal" class="mb-0" style="cursor: pointer;">
          <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.2246 5.0175L15.7423 12.499L23.2246 19.9805C24.2553 21.0125 24.2553 22.6877 23.2273 23.7226C22.1939 24.76 20.5209 24.7574 19.4875 23.7253L12 16.2385L4.51247 23.7253C3.47909 24.7574 1.80609 24.76 0.772705 23.7226C-0.258011 22.6879 -0.258013 21.0127 0.775369 19.9805L8.25769 12.499L0.775369 5.0175C-0.258013 3.98543 -0.258011 2.31023 0.772705 1.27536C1.28813 0.756718 1.96732 0.5 2.64398 0.5C3.32065 0.5 3.99717 0.759386 4.5126 1.27282L12.0001 8.75964L19.4876 1.27282C20.0031 0.756716 20.6796 0.5 21.3562 0.5C22.0329 0.5 22.7121 0.759386 23.2275 1.27536C24.258 2.31023 24.258 3.98543 23.2246 5.0175Z" fill="#015289"/>
          </svg>
        </span>
      </div>
      <div class="mb-3 mt-3 voucher-container p-3 d-flex flex-column">
        <template v-if="listVoucher?.length > 0">
          <div class="d-flex w-100" style="height: 120px;" v-for="(obj, index) in listVoucher" :key="index">
            <div style="object-fit: cover; position: relative;">
              <VoucherOutline :fill="fixedVoucher(obj) === 'fixed' ? '#015CA1' : fixedVoucher(obj) === 'percentage' ? '#F5831F' : '#ABABAB'"/>
              <VoucherFixedIcon class="voucher-icon" v-if="fixedVoucher(obj) === 'fixed'"/>
              <VoucherPercentageIcon class="voucher-icon" v-else-if="fixedVoucher(obj) === 'percentage'"/>
            </div>
            <div class="item border p-2 d-flex flex-column" style="width: 85%; height: 114px;">
              <div class="d-flex justify-content-between align-items-start mb-auto">
                <div>
                  <div class="title">{{ obj.name }}</div>
                  <div class="desc mt-1"> Jenis voucher:
                    <span class="desc" v-if="fixedVoucher(obj) === 'fixed'">Potongan Harga Rp. {{ toCurrency(obj.value) }}</span>
                    <span class="desc" v-else>Diskon {{ obj.value }} %</span>
                  </div>
                  <div class="expired-date">Berlaku sampai {{ getExpiredVoucher(obj) }}</div>
                </div>
                <div>
                  <a-checkbox :disabled="checkIsVoucherAvailable(obj)" :checked="obj.checked"  @change="(e) => handleOnCheckVoucher(e.target.checked, index)" style="color: #000000">
                  </a-checkbox>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-start">
                <div class="d-flex align-items-center" style="gap: 0px 3px;" v-if="isCombinedVoucher(obj)">
                  <div class="status">Kombinasi</div>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C2.68636 0 0 2.68636 0 6C0 9.31364 2.68636 12 6 12C9.31364 12 12 9.31364 12 6C12 2.68636 9.31364 0 6 0ZM8.60073 4.98546C8.64862 4.93071 8.68507 4.86695 8.70796 4.79791C8.73084 4.72887 8.73968 4.65595 8.73397 4.58345C8.72825 4.51094 8.70809 4.44031 8.67468 4.37571C8.64126 4.31111 8.59526 4.25384 8.53939 4.20728C8.48352 4.16072 8.41889 4.1258 8.34933 4.10458C8.27976 4.08336 8.20665 4.07627 8.1343 4.08372C8.06195 4.09118 7.99182 4.11303 7.92804 4.14798C7.86426 4.18294 7.80812 4.2303 7.76291 4.28727L5.41745 7.10127L4.20382 5.88709C4.10094 5.78773 3.96316 5.73275 3.82015 5.734C3.67713 5.73524 3.54032 5.7926 3.43919 5.89374C3.33806 5.99487 3.28069 6.13167 3.27945 6.27469C3.27821 6.41771 3.33319 6.55549 3.43255 6.65836L5.06891 8.29473C5.1225 8.34829 5.18667 8.3901 5.25732 8.41749C5.32797 8.44488 5.40354 8.45725 5.47924 8.45381C5.55493 8.45037 5.62908 8.4312 5.69695 8.39752C5.76483 8.36384 5.82494 8.31638 5.87345 8.25818L8.60073 4.98546Z" fill="#2CD397"/>
                  </svg>
                </div>
                <div class="d-flex align-items-center" style="gap: 0px 3px;" v-else>
                </div>
                <div class="detail" @click.prevent="() => detailVoucher = obj">
                  Lihat Detail
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-dark text-center">Anda tidak memiliki kupon</div>
        </template>
      </div>
      <div class="w-100">
        <a-button
          @click.prevent="() => listVoucher?.length > 0 ? onSubmit() : onCloseModal()"
          block
          class="mt-3 modal-button-submit"
          style="
            border-radius: 12px !important;
          "
        >
          {{ listVoucher?.length > 0 ? 'Konfirmasi' : 'Tutup' }}
        </a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import VoucherOutline from '@/components/Icons/VoucherOutline.vue'
import VoucherPercentageIcon from '@/components/Icons/VoucherPercentage.vue'
import VoucherFixedIcon from '@/components/Icons/VoucherFixed.vue'

export default {
  name: 'modalListVoucher',
  props: {
    showModal: {
      type: Boolean,
      default: () => false,
    },
    voucherOnFaktur: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    VoucherOutline,
    VoucherPercentageIcon,
    VoucherFixedIcon,
  },
  data: function () {
    return {
      loading: false,
      detailVoucher: null,
      listVoucher: [],
      isAlreadyCheckedNotCombine: false,
    }
  },
  computed: {
  },
  methods: {
    onCloseModal() {
      this.$emit('emitShowModal', false)
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    getExpiredVoucher(item) {
      if (item?.valid_until) {
        const simplifiedDate =  item?.valid_until?.split(' ')
        return moment(simplifiedDate?.length > 0 ? simplifiedDate[0] : '').format('DD MMM YYYY')
      } else {
        return '-'
      }
    },
    isCombinedVoucher(item) {
      if (item?.is_combine) {
        return item?.is_combine ?? false
      } else {
        return false
      }
    },
    fixedVoucher(item) {
      if (item?.value_type) {
        return item?.value_type
      } else {
        return null
      }
    },
    handleOnCheckVoucher(e, index) {
      const tempArray = this.listVoucher
      tempArray[index].checked = e
      this.listVoucher = tempArray
      const isCheckedNotCombine = tempArray.filter((obj) => this.isCombinedVoucher(obj) === false && obj.checked === true)
      this.isAlreadyCheckedNotCombine = isCheckedNotCombine?.length > 0
    },
    checkIsVoucherAvailable(item) {
      const isCheckedExist = this.listVoucher.filter((obj) => obj.checked === true)
      let status = false
      if (this.isAlreadyCheckedNotCombine) {
        if (item.checked) {
          status = false
        } else {
          status = true
        }
      } else {
        if (isCheckedExist?.length > 0 && !item.is_combine) {
          status = true
        } else {
          status = false
        }
      }
      return status
    },
    onSubmit() {
      this.$emit('emitSubmitModal', this.listVoucher)
    }
  },
  mounted() {
  },
  destroyed() {
  },
  watch: {
    voucherOnFaktur: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.listVoucher = this.voucherOnFaktur
      }
    },
  },
}
</script>

<style lang="scss">
  .modal-button-submit {
    width: 100%;
    background: #000000 !important;
    color: #FFFFFF !important;
    border: 1px solid #265C9B !important;
    border-radius: 12px !important;
  }
  .text-header-modal {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .text-header-modal-detail {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #015CA1;
  }
  .label-field {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
  }
  .voucher-container {
    max-height: 400px;
    overflow-y: auto;
    .voucher-icon {
      position: absolute;
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto;
      height: 100%;
    }
    .item {
      // border
      border-color: #D2D2D2 !important;
      border-left-width: 0px !important;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;

      .ant-checkbox-input {
        border-color: #015CA1 !important;
      }

      .title {
        font-family: Open Sans;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
      }
      .desc {
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
      }
      .expired-date {
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #767676;
      }
      .status {
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
      }
      .detail {
        font-family: Open Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #015CA1;
        cursor: pointer;
      }
    }
  }
  #modal-voucher .modal-body {
    padding: 0px !important;
  }
  .detail-voucher {
    .title {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }

    .desc {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
  }
</style>
