<template>
  <b-modal :no-close-on-backdrop="true" hide-footer hide-header centered v-model="modalError.visible" size="md">
    <div class="d-flex flex-column align-items-center text-center">
      <div class="mb-1">
        <Warning />
      </div>
      <span class="font-weight-bold mb-2 text-title">
        {{ $t('menu.purchase.checkout.order_failed') }}
      </span>
      <span class="mb-2">{{ modalError.message }}</span>
      <button class="btn-primary text-center w-fit btn-primary rounded-lg px-3 mb-1" @click="$router.push({ path: '/purchase/cart' })">
        {{ $t('menu.purchase.checkout.back_to_cart') }}
      </button>
      <span v-if="modalError.type === 'rejected'" style="color: red">
        {{ $t('menu.purchase.checkout.auto_delete') }}
      </span>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import Warning from '@/components/Icons/Warning.vue'

export default {
  name: 'modal-checkout-error',
  props: {
    modalError: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    Warning,
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.text-title {
  color: var(--biz-primary-100);
}
.btn-primary {
  background-color: #000000;
  color: #FFFFFF;
  border-color: #000000;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  &:hover {
    background-color: #FFFFFF !important;
    color: #000000 !important;
    border-color: #000000 !important;
  }

  &:active {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #000000 !important;
  }

  &:focus {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #000000 !important;
  }
}
</style>
