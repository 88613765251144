<template>
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.66016 2.91125H18.6602C18.8354 2.91117 19.0104 2.92228 19.1842 2.94453C19.1253 2.53099 18.9833 2.13366 18.7666 1.7765C18.55 1.41933 18.2633 1.10974 17.9238 0.866378C17.5843 0.623016 17.199 0.450928 16.7912 0.360489C16.3834 0.27005 15.9615 0.263134 15.5509 0.340157L3.21484 2.44625H3.20078C2.42644 2.59433 1.73784 3.03244 1.27563 3.67109C1.972 3.17578 2.8056 2.91015 3.66016 2.91125ZM18.6602 4.03625H3.66016C2.86477 4.03712 2.10222 4.35347 1.5398 4.91589C0.977374 5.47831 0.661025 6.24087 0.660156 7.03625V16.0363C0.661025 16.8316 0.977374 17.5942 1.5398 18.1566C2.10222 18.719 2.86477 19.0354 3.66016 19.0363H18.6602C19.4555 19.0354 20.2181 18.719 20.7805 18.1566C21.3429 17.5942 21.6593 16.8316 21.6602 16.0363V7.03625C21.6593 6.24087 21.3429 5.47831 20.7805 4.91589C20.2181 4.35347 19.4555 4.03712 18.6602 4.03625ZM16.4336 13.0363C16.1369 13.0363 15.8469 12.9483 15.6002 12.7835C15.3536 12.6186 15.1613 12.3844 15.0478 12.1103C14.9342 11.8362 14.9045 11.5346 14.9624 11.2436C15.0203 10.9526 15.1632 10.6854 15.3729 10.4756C15.5827 10.2658 15.85 10.123 16.141 10.0651C16.4319 10.0072 16.7335 10.0369 17.0076 10.1504C17.2817 10.264 17.516 10.4562 17.6808 10.7029C17.8456 10.9496 17.9336 11.2396 17.9336 11.5363C17.9336 11.9341 17.7756 12.3156 17.4943 12.5969C17.2129 12.8782 16.8314 13.0363 16.4336 13.0363Z" :fill="color || '#F5831F'"/>
  </svg>
</template>

<script>
export default {
  name: 'Coin',
  props: ['rotate', 'color'],
}
</script>
