<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_539_16621)">
<path d="M10.666 2H0.666016V10.6667H10.666V2Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.666 5.3335H13.3327L15.3327 7.3335V10.6668H10.666V5.3335Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.66667 13.9998C4.58714 13.9998 5.33333 13.2536 5.33333 12.3332C5.33333 11.4127 4.58714 10.6665 3.66667 10.6665C2.74619 10.6665 2 11.4127 2 12.3332C2 13.2536 2.74619 13.9998 3.66667 13.9998Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3327 13.9998C13.2532 13.9998 13.9993 13.2536 13.9993 12.3332C13.9993 11.4127 13.2532 10.6665 12.3327 10.6665C11.4122 10.6665 10.666 11.4127 10.666 12.3332C10.666 13.2536 11.4122 13.9998 12.3327 13.9998Z" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_539_16621">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {
  name: 'TruckFlatIcon',
}
</script>

<style>

</style>
