<template>
  <svg width="100%" height="114" viewBox="0 0 61 114" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H61V114H0V92.7979C3.97441 92.5386 7.11719 89.2575 7.11719 85.248C7.11719 81.2385 3.97441 77.9575 0 77.6982V69.5947C3.97441 69.3354 7.11719 66.0544 7.11719 62.0449C7.11719 58.0354 3.97441 54.7543 0 54.495V46.3897C3.97441 46.1304 7.11719 42.8493 7.11719 38.8398C7.11719 34.8303 3.97441 31.5493 0 31.29V23.1865C3.97441 22.9272 7.11719 19.6461 7.11719 15.6367C7.11719 11.6272 3.97441 8.34614 0 8.08683V0Z" :fill="fill"/>
  </svg>
</template>

<script>

export default {
  name: 'voucher-outline',
  props: ['fill'],
}
</script>
