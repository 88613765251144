<template>
  <div @click="showModalPotensial">
    <a-row class="checkout-calculation px-4 py-4" v-if="potensialPromotion?.length || dataPercentage?.length">
      <a-col class="title">
        {{ $t('purchase.checkout.yourPotensialPoints') }}
        <a-badge
          :count="potensialPromotion?.length + (flag === 'transaction' ? (dataPercentage[0]?.data[0]?.promotion_data?.length || 0) : dataPercentage?.length)"
          :number-style="{
        backgroundColor: 'var(--biz-secondary-100)',
        color: 'var(--biz-brand-base)',
        boxShadow: '0 0 0 1px #d9d9d9 inset',
      }"
          class="ml-4"
        />
        <a-icon
          type="right"
          class="float-right right-button"
        />
      </a-col>
    </a-row>
    <!-- Potensial Coin Modal -->
    <a-modal
      v-model="visible"
      :title="potensialTitle"
      @ok="handleOk"
      :footer="null"
    >
      <!-- Card Bonus #1 -->
      <div v-if="flag === 'transaction'" class="d-flex p-2 mb-3" style="background: #F4F4F4; border-radius: 10px">
        <div class="mr-1">
          <img
            src="https://ik.imagekit.io/fepca/Group_15j3l_Yim.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659491692594"
            alt="info" />
        </div>
        <div>
            Untuk bonus <b>Coin & Voucher</b>
            akan didapat seletah proses <b style="color: var(--biz-secondary-100)">selesai</b>
        </div>
      </div>
      <!-- <div class="card">
        <div class="card-header d-flex align-items-center custom-inside-modal-header">
          <Tags />
          <div class="title-inside-modal">
            {{ $t('purchase.checkout.kinoBonusTitle') }}
          </div>
        </div>
        <div class="pl-3 pr-3 pt-3">
          <h5 class="card-title text-muted">{{ $t('purchase.checkout.kinoPotensiBonusDesc') }} </h5>
          <div class="card inside-card-desc">
            <div class="card-body d-flex align-items-center">
              <span class="">
                <Coin />
              </span>
              <h5 class="kino-coin-value">
                {{toCurrency(Math.round(grandTotal*potensialPointIncreased))}} Coin
              </h5>
            </div>
          </div>
        </div>
      </div> -->
      <div class="card" v-for="(item, index) in potensialPromotion" :key="index">
        <div class="card-header d-flex align-items-center custom-inside-modal-header">
          <Tags :color="'var(--biz-secondary-100)'"/>
          <!-- <div class="title-inside-modal" v-if="item.gift_type === 'coin'">
            {{ $t('purchase.checkout.kinoBonusTitle') }}
          </div> -->
          <div class="title-inside-modal">
            {{item.name}}
          </div>
        </div>
        <div class="pl-3 pr-3 pt-3">
          <h5 class="card-title text-muted" v-if="item.gift_type === 'coin'">{{ $t('purchase.checkout.kinoBonusDesc') }} </h5>
          <h5 class="card-title text-dark" v-if="item.gift_type === 'discount'">{{ $t('purchase.checkout.discountsDesc') }} </h5>
          <h5 class="card-title text-muted" v-if="item.gift_type === 'voucher'">{{ $t('purchase.checkout.discountVoucherDesc') }} </h5>
          <h5 class="card-title text-muted" v-if="item.gift_type === 'product'">{{ $t('purchase.checkout.discountProductDesc') }} </h5>
          <!-- Inside modal card -->
          <div class="card inside-card-desc" v-if="item.gift_type === 'coin'">
            <div class="card-body d-flex align-items-center">
              <span class="" v-if="item.gift_type === 'coin'">
                <Coin :color="'var(--biz-secondary-100)'"/>
              </span>
              <h5 class="kino-coin-value">
                {{toCurrency(Math.round(item.value))}} Coin
              </h5>
            </div>
          </div>
          <div class="card inside-card-desc" v-if="item.gift_type === 'voucher'">
            <div class="card-body d-flex align-items-center">
              <div id="box-qty" v-if="item.value_type === 'fixed'">
                <p>Rp. {{toCurrency(Math.round(item.value))}}</p>
              </div>
              <div id="box-qty" v-else>
                <p>{{ item.value }}%</p>
              </div>
            </div>
          </div>
          <div class="card inside-card-desc-basic" v-if="item.gift_type === 'product'">
            <div v-for="({qty, uom, catalog}, index) in item.gift" :key="index">
              <a-divider v-if="index > 0"/>
              <div class="d-flex align-items-center">
                <div class="badge-qty-unit">{{ qty }} {{ uom }}</div>
                <div class="text-basic-title mb-0 ml-2">{{ catalog?.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Card Bonus #2 -->
      <!-- <div class="card">
        <div class="card-header d-flex align-items-center custom-inside-modal-header">
          <a-icon
            type="tags"
            class="float-left right-button text-light"
          />
          <div class="title-inside-modal">
            {{ $t('purchase.checkout.extraGoodsTitle') }}
          </div>
        </div>
        <div class="card-body">
          <h5 class="card-title text-muted">{{ $t('purchase.checkout.kinoExtraGoodsDesc') }} </h5>
          <div class="card inside-card-desc">
            <div class="card-body d-flex align-items-center">
              <div id="box-qty">
                <p>10 PCS</p>
              </div>
              <h5 class="kino-coin-value">
                Ellips with hair smoothing varian merah
              </h5>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Card Bonus #3 -->
      <div v-if="dataPercentage?.length > 0">
        <div v-if="flag === 'checkout'" >
          <div v-for="(item, index) in dataPercentage" :key="index" class="card">
            <div class="card-header d-flex align-items-center custom-inside-modal-header">
              <Tags :color="'var(--biz-secondary-100)'"/>
              <div class="title-inside-modal">
                {{ item.name }}
              </div>
            </div>
            <div class="card-body">
              <h5 class="card-title text-muted">{{ $t('purchase.checkout.discountPercentDesc') }} </h5>
              <div>
                <div v-if="item.gift[0].value" class="card inside-card-desc">
                  <div class="card-body d-flex align-items-center">
                    <div id="box-qty">
                      <p>
                        {{ item.gift[0].value_type === 'fixed' ? 'Rp. ' : '' }}
                        {{ item.gift[0].value_type === 'fixed' ? toCurrency(item.gift[0].value) : item.gift[0].value }}
                        {{ item.gift[0].value_type === 'percentage' ? '%' : '' }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="flag === 'transaction' && dataPercentage.length > 0">
          <div v-for="(item, index) in dataPercentage[0].data[0].promotion_data" :key="index" class="card">
            <div class="card-header d-flex align-items-center custom-inside-modal-header">
              <Tags :color="'var(--biz-secondary-100)'"/>
              <div class="title-inside-modal">
                {{ item.name }}
              </div>
            </div>
            <div class="card-body">
              <h5 class="card-title text-muted">{{ item.gift[0].value_type === 'percentage' ? $t('purchase.checkout.discountPercentDesc') : $t('purchase.checkout.discountCutDesc') }} </h5>
              <div>
                <div v-if="item.value" class="card inside-card-desc">
                  <div class="card-body d-flex align-items-center">
                    <div id="box-qty">
                      <p>
                        {{ item.gift[0].value_type === 'fixed' ? 'Rp. ' : '' }}
                        {{ item.gift[0].value_type === 'fixed' ? toCurrency(item.gift[0].gift_value) : item.gift[0].gift_value }}
                        {{ item.gift[0].value_type === 'percentage' ? '%' : '' }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Tags from '@/components/Icons/Tags.vue'
import Coin from '@/components/Icons/Coin.vue'

export default {
  props: ['potensialCoin', 'carts', 'pointUsed', 'dataPercentage', 'flag', 'grandTotal', 'potensialPromotion'],
  components: {
    Tags,
    Coin,
  },
  data() {
    return {
      visible: false,
      potensialTitle: this.$t('purchase.checkout.yourPotensialPoints'),
      tempDataPrice: 5,
      tempUsedPoints: 0,
      potensialPointIncreased: 0.001,
    }
  },
  computed: {
    totalPriceProduct() {
      if (!this.carts?.length) {
        return 0
      }
      let price = 0
      const warehouse_id = this.carts[0].id
      if (this.flag === 'checkout') {
        const { cart_list } = this.carts.find((item) => item.id === warehouse_id)
        price = cart_list.reduce((acc, current) => {
          return acc + current.quantity * current.price.selling_price
        }, 0)
      } else {
        const cart_list = this.carts.filter((item) => item.id === warehouse_id)
        price = cart_list && cart_list?.reduce((acc, current) => {
          return acc + current.qty * current.selling_price
        }, 0)
      }
      return price
    },
  },
  methods: {
    showModalPotensial() {
      this.visible = true
    },
    handleOk(e) {
      this.visible = false
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
  },
  mounted() {
    this.$store
      .dispatch('loyalty/GETLOYALTYACTIVE', {})
      .then(({ data }) => {
        this.potensialPointIncreased = data.value
      })
      .catch((err) => console.error(err))
      .finally(() => {
        this.loading = false
      })
    // this.getPaymentMethod()
  },
  watch: {
    pointUsed(value) {
      if (value) {
        if (value > this.totalPriceProduct) {
          this.$emit('emitLocalUsedPoints', this.totalPriceProduct)
          this.tempUsedPoints = this.totalPriceProduct
        } else {
          this.$emit('emitLocalUsedPoints', value)
          this.tempUsedPoints = value
        }
      } else {
        this.$emit('emitLocalUsedPoints', 0)
        this.tempUsedPoints = 0
      }
    },
  },
}
</script>

<style lang="scss">
#box-qty {
  // width: 65px;
  padding: 10px;
  height: 35px;
  background-color: var(--biz-secondary-100);
  border-radius: 10px;
}

#box-qty p {
  margin: 0px;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  color: var(--biz-brand-base);
  font-weight: bold;
}

.kino-coin-value {
  font-weight: bold;
  font-size: 16px;
  color: var(--biz-secondary-100);
  margin-left: 20px;
  margin-bottom: 5px;
}

.inside-card-desc {
  border: 1px solid var(--biz-secondary-100);
  background-color: var(--biz-tertiary-20);
}
.inside-card-desc-basic {
  border: 1px solid var(--biz-secondary-100);
  background-color: var(--biz-brand-base);
  padding: 10px;
}

.custom-inside-modal-header {
  background-color: var(--biz-primary-100);
}

.title-inside-modal {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  margin-left: 20px;
  color: var(--biz-brand-base) !important;
}

.checkout-calculation {
  background: var(--biz-brand-base);
  border: 0.716px solid var(--biz-primary-20);
  border-radius: 10px;
  cursor: pointer;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #000000;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .point {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: var(--biz-secondary-100);
  }

  .total {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: var(--biz-secondary-100);
  }

  .term-payment {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #000000;
  }

  .confirm-payment {
    height: 33px;
    background: var(--biz-primary-100);
    border-radius: 5px;
    color: var(--biz-brand-base);
    cursor: pointer;
  }

  .right-button {
    color: var(--biz-primary-100);
    cursor: pointer;
    margin-top: 2px;
  }

  .button-disabled {
    height: 33px;
    border-radius: 5px;
    background: #ababab;
    color: white;
    cursor: not-allowed;
  }
}
.badge-qty-unit {
  color: white;
  background: var(--biz-primary-100);
  border-radius: 4px;
  padding: 6px 10px;
  white-space: nowrap;
}
.text-basic-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}
</style>
