<template>
  <svg width="23" height="30" viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.506 27.381L0 14.875V17.4298C0 18.1069 0.268259 18.7584 0.753679 19.231L10.7048 29.1821C11.7012 30.1785 13.3235 30.1785 14.3199 29.1821L22.2527 21.2493C23.2491 20.253 23.2491 18.6306 22.2527 17.6342L12.506 27.381Z" :fill="color || '#F5831F'"/>
    <path d="M10.7048 22.7946C11.7012 23.791 13.3235 23.791 14.3199 22.7946L22.2527 14.8618C23.2491 13.8654 23.2491 12.2431 22.2527 11.2467L12.3016 1.29559C11.8163 0.822054 11.1657 0.556278 10.4876 0.554688L2.55485 0.554688C1.14968 0.554688 0 1.70437 0 3.10953V11.0423C0 11.7194 0.268259 12.3709 0.753679 12.8435L10.7048 22.7946ZM5.42905 4.38696C5.85254 4.38696 6.25869 4.55519 6.55814 4.85464C6.85759 5.1541 7.02583 5.56024 7.02583 5.98374C7.02583 6.40723 6.85759 6.81337 6.55814 7.11283C6.25869 7.41228 5.85254 7.58051 5.42905 7.58051C5.00556 7.58051 4.59941 7.41228 4.29995 7.11283C4.0005 6.81337 3.83227 6.40723 3.83227 5.98374C3.83227 5.56024 4.0005 5.1541 4.29995 4.85464C4.59941 4.55519 5.00556 4.38696 5.42905 4.38696Z" :fill="color || '#F5831F'"/>
</svg>
</template>

<script>
export default {
  name: 'Tags',
  props: ['color'],
}
</script>
