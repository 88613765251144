<template>
  <div>
    <a-row class="checkout-shipping" v-if="selectedShippingType !== 'pickup'">
      <div :span="24" class="p-4 dropshipper-container" @click="handleClickDropshipper">
        <div>
          <a-checkbox class="default-checkbox" :checked="isDropshipper"/>
        </div>
        <div>
          <h5>Kirim Sebagai Dropshipper</h5>
          <div style="font-size: 12px; color: #777777">Pilihan ini dapat digunakan untuk pengiriman sebagai dropshipper.</div>
          <div class="name" v-if="isDropshipper">
            <b>{{ dropShipping.first_name + ' ' + dropShipping.last_name }}</b> <span class="gray-title">{{ dropShipping.phone }}</span>
          </div>
        </div>
      </div>
    </a-row>
    <a-modal
      v-model="modalDropshipper"
      title="Kirim Sebagai Dropshipper"
      :footer="null"
      :closable="true"
      @cancel="handleCancelDropshipper"
    >
      <div :span="24" class="checkout-shipping__content mb-1 px-2">
        <DropshipperForm
          @handleCancelButton="handleCancelButton"
          @saveDropshipper="saveDropshipper"
          :modalDropshipper="modalDropshipper"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
import DropshipperForm from '@/components/Purchase/Checkout/Dropshipper/dropshipForm.vue'
// import { v4 } from 'uuid'

export default {
  components: {
    DropshipperForm,
  },
  data: function () {
    return {
      dropShipping: {
        first_name: '',
        last_name: '',
        phone: '',
      },
      isDropshipper: false,
      modalDropshipper: false,
      isModal: false,
    }
  },
  props: {
    selectedShippingType: {
      type: String,
      default: () => 'pickup',
    },
  },
  watch: {
    selectedShippingType(value) {
      if (value === 'pickup') {
        this.isDropshipper = false
        this.$emit('emitIsDropshipper', false)
      }
    },
  },
  computed: {
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
  },
  methods: {
    handleCancelDropshipper() {
      this.modalDropshipper = false
    },
    handleClickDropshipper() {
      if (this.isDropshipper === false) {
        this.modalDropshipper = true
      } else {
        this.isDropshipper = !this.isDropshipper
        this.$emit('emitIsDropshipper', !this.isDropshipper)
      }
    },
    handleCancelButton() {
      this.isCreateNewAddress = false
      this.modalVisible = false
    },
    saveDropshipper(values) {
      this.dropShipping = values
      this.isDropshipper = true
      this.modalDropshipper = false
      this.$emit('emitIsDropshipper', true)
      this.$emit('emitIsDropShipping', values)
    },
  },
}
</script>

<style lang="scss" scoped>
.checkout-shipping {
  margin-bottom: 1rem !important;
  border: 0.716px solid var(--biz-primary-20) !important;
  border-radius: 10px;

  &__title {
    background: var(--biz-brand-base);
    height: 40px;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: var(--biz-base-100);
    padding: 35px;
    border-radius: 10px;

    @media (max-width: 600px) {
      font-size: 14px;
      padding-bottom: 20px;
    }
  }

  &__content {
    background: var(--biz-brand-base);
    height: auto;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: var(--biz-base-100);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__button {
    background: var(--biz-brand-base);
    width: 100%;
    height: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 14px;

    @media (max-width:600px) {
      font-size: 12px;
    }

    div {
      height: 35px;
      box-sizing: border-box;
      border: 1px solid var(--biz-base-100);
      border-radius: 6px;
      color: var(--biz-base-100);
      cursor: pointer;
      background: var(--biz-brand-base);
      padding: 0 10px;
    }

    div:hover {
      background: var(--biz-brand-base);
      color: var(--biz-base-100);
    }
  }
}
.gray-title {
  color: var(--biz-base-20);
}
.dropshipper-container {
  display: flex;
  gap: 10px;
  cursor: pointer;
}
</style>
<style lang="scss">
  .default-checkbox .ant-checkbox-inner {
      accent-color: var(--biz-brand) !important;
      color: var(--biz-base-100) !important;
      border: 1.3px solid #767676;
  }
</style>
