<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.25079 8.25095C10.138 6.36366 10.138 3.30276 8.25079 1.41547C6.36353 -0.471823 3.30269 -0.471823 1.41544 1.41547C-0.471813 3.30276 -0.471813 6.36366 1.41544 8.25095C3.30345 10.139 6.36353 10.139 8.25079 8.25095ZM27.584 20.7491C25.6967 18.8618 22.6359 18.8618 20.7486 20.7491C18.8614 22.6363 18.8614 25.6972 20.7486 27.5845C22.6359 29.4718 25.6967 29.4718 27.584 27.5845C29.472 25.6972 29.472 22.6371 27.584 20.7491ZM27.4375 3.27104L25.7292 1.56274C24.7852 0.618713 23.2552 0.618713 22.3119 1.56274L1.5627 22.3124C0.6187 23.2564 0.6187 24.7865 1.5627 25.7297L3.27097 27.438C4.21498 28.382 5.74502 28.382 6.68827 27.438L27.4375 6.6884C28.3815 5.74513 28.3815 4.21506 27.4375 3.27104Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'voucher-percentage',
}
</script>
