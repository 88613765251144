<template>
  <b-modal id="modal-checkout-waiting" :no-close-on-backdrop="true" hide-footer hide-header centered v-model="modalCheckoutWaiting" size="md">
    <div class="d-flex flex-column align-items-center text-center py-3">
      <template v-if="checkoutWaitingExpired">
        <span class="font-weight-bold mb-2 name">Mohon Maaf Order Pending</span>
        <span class="mb-2">Silahkan hubungi customer service atau cek history transaksi anda.</span>
        <button
          class="text-center w-fit mt-2 btn-primary rounded-lg px-3"
          @click="onRedirect"
        >
          OK
        </button>
      </template>
      <template v-else>
        <span class="font-weight-bold mb-2 name">Mohon Menunggu</span>
        <span class="mb-2">Pesanan anda sedang di proses</span>
        <DefaultLoader class="rotate-img" :width="50" :height="50"/>
      </template>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import DefaultLoader from '@/components/Icons/DefaultLoader.vue'

export default {
  name: 'modal-checkout-error',
  props: {
    modalCheckoutWaiting: {
      type: Boolean,
      default: () => false,
    },
    checkoutWaitingExpired: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    DefaultLoader,
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
    onRedirect() {
      this.checkoutWaitingExpired = false
      this.modalCheckoutWaiting = false
      this.$router.replace({ path: '/' })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-primary {
  background-color: #000000;
  color: #FFFFFF;
  border-color: #000000;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  &:hover {
    background-color: #FFFFFF !important;
    color: #000000 !important;
    border-color: #000000 !important;
  }

  &:active {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #000000 !important;
  }

  &:focus {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #000000 !important;
  }
}

.rotate-img {
  animation: rotation 1s infinite linear;
  height: 55px;
  width: auto
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
