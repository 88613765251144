<template>
  <b-modal hide-footer hide-header centered v-model="showModal" body-class="w-[352]" @hidden="resetModal">
    <div class="d-flex flex-column align-items-center text-center">
      <div class="mb-1">
        <Warning :color="'var(--biz-secondary-100)'"/>
      </div>
      <span>Metode pembayaran akan di reset saat apply voucher cashback</span>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import Warning from '@/components/Icons/Warning.vue'

export default {
  name: 'modal-reset-payment',
  props: {
    showModal: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    Warning,
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
    resetModal() {
      this.$emit('emitCloseModal', false)
    }
  },
}
</script>

<style lang="scss">
</style>
