<template>
  <a-form :form="form" @submit.prevent="submit">
    <a-row>
      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" label="Nama Depan Pengirim / Dropshipper">
          <a-input
            placeholder="Nama Depan Pengirim / Dropshipper"
            class="input-basic"
            v-decorator="['first_name', {
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Nama Depan Pengirim wajib diisi',
                },
                {
                  pattern: new RegExp(/^.{3,15}$/, ''),
                  message: '(Minimal karakter 3 dan Maksimal 15)'
                },
                {
                  pattern: new RegExp(/^\S+(?: \S+)*$/, ''),
                  message: '(Tidak ada spasi di depan dan belakang)',
                },
                {
                  pattern: new RegExp(/^[a-zA-Z ]*$/, ''),
                  message: '(Hanya alphabets)',
                }
              ],
            }]"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" label="Nama Belakang Pengirim / Dropshipper">
          <a-input
            v-decorator="['last_name', {
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Nama Belakang Pengirim wajib diisi',
                },
                {
                  pattern: new RegExp(/^.{3,15}$/, ''),
                  message: '(Minimal karakter 3 dan Maksimal 15)'
                },
                {
                  pattern: new RegExp(/^\S+(?: \S+)*$/, ''),
                  message: '(Tidak ada spasi di depan dan belakang)',
                },
                {
                  pattern: new RegExp(/^[a-zA-Z ]*$/, ''),
                  message: '(Hanya alphabets)',
                }
              ],
            }]"
            placeholder="Nama Belakang Pengirim / Dropshipper"
            class="input-basic"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" label="Nomor Telepon Pengirim / Dropshipper">
          <a-input
            v-decorator="['phone', {
              rules: [
                {
                  required: true,
                  message: 'Nomor Telepon wajib diisi',
                },
                {
                  pattern: new RegExp(/^[\+]?[(]?[0-9]{6}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{1,6}$/, ''),
                  message: 'Nomor Telepon tidak sesuai format'
                }
              ],
            }]"
            placeholder="Nomor Telepon Pengirim / Dropshipper"
            class="input-basic"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-button
          type="primary"
          html-type="submit"
          block
          class="mt-4"
          style="
            background: #000000 !important;
            color: #FFFFFF !important;
            border: 1px solid #000000 !important;
          "
        >
          <div v-if="!loading"> Simpan </div>
          <div v-if="loading"> <a-icon type="loading" /> </div>
        </a-button>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
export default {
  name: 'dropship-form',
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    modalDropshipper: {
      type: Boolean,
      default: () => false,
    },
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
      countryList: [],
      cityList: [],
      areaList: [],
      initialValueCountry: null,
    }
  },
  watch: {
    modalDropshipper(value) {
      this.form = this.$form.createForm(this)
    },
  },
  methods: {
    submit () {
      this.form.validateFieldsAndScroll((err, values) => {
        if (err) return

        this.$emit('saveDropshipper', values)
      })
    },
    fetchRegionCountry () {
      this.$store.dispatch('data/GETREGIONCOUNTRY', this.$store.state.app.vendor_hashid)
        .then((data) => {
          this.countryList = data.list

          const getIndonesia = data.list.find(item => item.label.toLowerCase() === 'indonesia')

          if (getIndonesia) {
            this.handleSelectCountry(getIndonesia.value)
            this.initialValueCountry = getIndonesia.value
          }
        })
    },
    fetchAreaList (parentId, param) {
      this.$store.dispatch('data/GETAREALLIST', {
        parentId,
      })
        .then((data) => {
          switch (param) {
            case 'city':
              this.cityList = data.list
              break

            case 'area':
              this.areaList = data.list
              break

            default:
              break
          }
        })
    },
    handleSelectCountry (e) {
      this.fetchAreaList(e, 'city')
    },
    handleSelectCity (e) {
      const key = this.cityList.find(item => item.label === e)
      this.fetchAreaList(key.value, 'area')
    },
    handleSelectArea (e) {
      const findArea = this.areaList.find(item => item.label === e)
      this.form.setFieldsValue({
        code_pos: findArea.postcode,
      })
    },
  },
  created () {
    this.fetchRegionCountry()
  },
}
</script>

<style>
  .input-basic::placeholder {
    color: #E3E3E3!important;
  }
  .input-form {
    margin-bottom: 10px!important;
    margin-top: 0px!important;
  }
</style>
