var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('a-row',[_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":"Nama Depan Pengirim / Dropshipper"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['first_name', {
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Nama Depan Pengirim wajib diisi',
              },
              {
                pattern: new RegExp(/^.{3,15}$/, ''),
                message: '(Minimal karakter 3 dan Maksimal 15)'
              },
              {
                pattern: new RegExp(/^\S+(?: \S+)*$/, ''),
                message: '(Tidak ada spasi di depan dan belakang)',
              },
              {
                pattern: new RegExp(/^[a-zA-Z ]*$/, ''),
                message: '(Hanya alphabets)',
              }
            ],
          }]),expression:"['first_name', {\n            rules: [\n              {\n                required: true,\n                whitespace: true,\n                message: 'Nama Depan Pengirim wajib diisi',\n              },\n              {\n                pattern: new RegExp(/^.{3,15}$/, ''),\n                message: '(Minimal karakter 3 dan Maksimal 15)'\n              },\n              {\n                pattern: new RegExp(/^\\S+(?: \\S+)*$/, ''),\n                message: '(Tidak ada spasi di depan dan belakang)',\n              },\n              {\n                pattern: new RegExp(/^[a-zA-Z ]*$/, ''),\n                message: '(Hanya alphabets)',\n              }\n            ],\n          }]"}],staticClass:"input-basic",attrs:{"placeholder":"Nama Depan Pengirim / Dropshipper"}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":"Nama Belakang Pengirim / Dropshipper"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['last_name', {
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Nama Belakang Pengirim wajib diisi',
              },
              {
                pattern: new RegExp(/^.{3,15}$/, ''),
                message: '(Minimal karakter 3 dan Maksimal 15)'
              },
              {
                pattern: new RegExp(/^\S+(?: \S+)*$/, ''),
                message: '(Tidak ada spasi di depan dan belakang)',
              },
              {
                pattern: new RegExp(/^[a-zA-Z ]*$/, ''),
                message: '(Hanya alphabets)',
              }
            ],
          }]),expression:"['last_name', {\n            rules: [\n              {\n                required: true,\n                whitespace: true,\n                message: 'Nama Belakang Pengirim wajib diisi',\n              },\n              {\n                pattern: new RegExp(/^.{3,15}$/, ''),\n                message: '(Minimal karakter 3 dan Maksimal 15)'\n              },\n              {\n                pattern: new RegExp(/^\\S+(?: \\S+)*$/, ''),\n                message: '(Tidak ada spasi di depan dan belakang)',\n              },\n              {\n                pattern: new RegExp(/^[a-zA-Z ]*$/, ''),\n                message: '(Hanya alphabets)',\n              }\n            ],\n          }]"}],staticClass:"input-basic",attrs:{"placeholder":"Nama Belakang Pengirim / Dropshipper"}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":"Nomor Telepon Pengirim / Dropshipper"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone', {
            rules: [
              {
                required: true,
                message: 'Nomor Telepon wajib diisi',
              },
              {
                pattern: new RegExp(/^[\+]?[(]?[0-9]{6}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{1,6}$/, ''),
                message: 'Nomor Telepon tidak sesuai format'
              }
            ],
          }]),expression:"['phone', {\n            rules: [\n              {\n                required: true,\n                message: 'Nomor Telepon wajib diisi',\n              },\n              {\n                pattern: new RegExp(/^[\\+]?[(]?[0-9]{6}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{1,6}$/, ''),\n                message: 'Nomor Telepon tidak sesuai format'\n              }\n            ],\n          }]"}],staticClass:"input-basic",attrs:{"placeholder":"Nomor Telepon Pengirim / Dropshipper"}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-button',{staticClass:"mt-4",staticStyle:{"background":"#000000 !important","color":"#FFFFFF !important","border":"1px solid #000000 !important"},attrs:{"type":"primary","html-type":"submit","block":""}},[(!_vm.loading)?_c('div',[_vm._v(" Simpan ")]):_vm._e(),(_vm.loading)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}})],1):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }