var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticClass:"payment-method-container"},[_c('a-row',{attrs:{"span":24}},[_c('a-col',{staticStyle:{"width":"50%"}},[_c('div',{staticClass:"payment-method-main-title"},[_vm._v(" Metode Pengiriman ")])]),_c('a-col',[_c('a-radio-group',{staticClass:"w-100 list-payment-method",model:{value:(_vm.selectedShippingType),callback:function ($$v) {_vm.selectedShippingType=$$v},expression:"selectedShippingType"}},[_c('div',{class:`box-payment-method${_vm.selectedShippingType === 'pickup' ? '__active' : ''}`,style:({
            width: _vm.isMobileView ? '50%' : '180px'
          }),on:{"click":function($event){$event.preventDefault();return (() => {
            _vm.$emit('emitSelectedShippingType', 'pickup')
            _vm.$emit('emitIsDropshipper', false)
          }).apply(null, arguments)}}},[_c('a-radio',{staticClass:"default-checkbox",attrs:{"value":'pickup'}}),_c('div',[_c('b',[_vm._v("Ambil Sendiri")])])],1),_c('div',{class:`box-payment-method${_vm.selectedShippingType === 'shipping' ? '__active' : ''}`,style:({
            width: _vm.isMobileView ? '50%' : '180px'
          }),on:{"click":function($event){$event.preventDefault();return (() => {
            _vm.$emit('emitSelectedShippingType', 'shipping')
          }).apply(null, arguments)}}},[_c('a-radio',{staticClass:"default-checkbox",attrs:{"value":'shipping'}}),_c('div',[_c('b',[_vm._v("Dikirim ke Alamat Pengiriman")])])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }