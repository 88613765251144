<template>
  <img :src="require('@/resources/images/loading.png')" :width="width" :height="height"/>
</template>

<script>
export default {
  name: 'DefaultLoader',
  props: {
    width: {
      type: Number,
      default: 50,
    },
    height: {
      type: Number,
      default: 50,
    },
  },
}
</script>
