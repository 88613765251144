<template>
  <div class="cui__uncontent">
    <div class="biz-container pb-5">
      <div class="checkout pb-5">
        <div class="ant-modal-root order-detail_loader" v-if="loading">
          <div class="ant-modal-mask">
            <div class="ant-modal-wrap d-flex justify-content-center align-items-center">
              <a-spin></a-spin>
            </div>
          </div>
        </div>

        <ModalCheckoutError :modalError="modalError" />
        <ModalResetPayment :showModal="showNotificationResetPayment" @emitCloseModal="(e) => showNotificationResetPayment = e"/>
        <ModalCheckoutWaiting :modalCheckoutWaiting="modalCheckoutWaiting" :checkoutWaitingExpired="checkoutWaitingExpired" />

        <template v-if="!loading">
          <a-row class="checkoutTitle" id="checkoutTitle">
            <a-col :span="24" class="d-flex justify-content-start align-items-center">

              <div class="mt-5">
                <h3 class="checkout-title">
                  Checkout
                </h3>
              </div>
            </a-col>
          </a-row>

          <a-row class="mb-3" v-if="carts.length" :gutter="16">
            <a-col class="mb-3" :xs="24" :sm="24" :md="15">
              <ShippingMethodComponent :selectedShippingType="selectedShippingType"
                @emitSelectedShippingType="emitSelectedShippingType" @emitIsDropshipper="emitIsDropshipper" />
              <ShippingComponent :selectedShippingAddress="selectedShippingAddress"
                :listAvailableShippingAddress="listAvailableShippingAddress"
                @setSelectedShippingAddress="setSelectedShippingAddress" @fetchShippingAddress="fetchShippingAddress"
                @emitIsDropshipper="emitIsDropshipper" @emitIsDropShipping="emitIsDropShipping"
                :selectedShippingType="selectedShippingType" :warehouseData="warehouseData" ref="shippingComponent"
                id="shippingComponent" />
              <BillingComponent :selectedBillingAddress="selectedBillingAddress"
                :listAvailableBillingAddress="listAvailableBillingAddress"
                @setSelectedBillingAddress="setSelectedBillingAddress" @fetchBillingAddress="fetchBillingAddress"
                :selectedShippingAddress="selectedShippingAddress" ref="billingComponent" id="billingComponent"
                :selectedShippingType="selectedShippingType" />
              <DropshipperComponent @emitIsDropshipper="emitIsDropshipper" @emitIsDropShipping="emitIsDropShipping"
                :selectedShippingType="selectedShippingType" />
              <!-- Ini Voucher -->
              <CartComponent
                :isValidToPay="isValidToPay"
                :carts="carts" :paymentList="paymentList" @setSelectedPayment="setSelectedPayment"
                ref="cartComponent" id="cartComponent" :currency="currency" @emitLocalUsedPoints="emitLocalUsedPoints"
                :pointUsed="pointUsed" @creditUsed="creditUsed"
                @handleCurrentTotalCreditLimit="handleCurrentTotalCreditLimit"
                @setSelectedMixPayment="setSelectedMixPayment" :sellerId="sellerId" :grandTotal="grandTotal"
                :grandTotalBeforeCreditLimit="grandTotalBeforeCreditLimit" :depositReturnUsed="depositReturnUsed"
                :voucherOnFaktur="voucherOnFaktur" @emitSelectedVoucher="onSelectedVoucherOnFaktur"
                :usedPoints="tempUsedPoints" :totalProduct="totalProduct"
                :distination_id="+selectedShippingAddress?.area_id || 0"
                @emitSelectedShippingMethod="emitSelectedShippingMethod" :selectedShippingType="selectedShippingType"
                :totalWeight="totalWeight" :selectedShippingAddress="selectedShippingAddress"
                :selectedMethod="selectedMethod" :selectedMixMethod="selectedMixMethod"
                @emitSelectedMethod="e => (selectedMethod = e)" @emitSelectedMixMethod="e => (selectedMixMethod = e)"
                :isInsurance="isInsurance" :insuranceCost="insuranceCost" @emitIsInsurance="e => (isInsurance = e)"
                @emitInsuranceCost="e => (insuranceCost = e)" @emitPhoneOVO="e => (phoneOVO = e)" @handleCheckoutPage="onOpenPaymentModal"
                @handlePayNow="onValidateCheckout"
              />
              <!-- <PointComponent /> -->
            </a-col>

            <a-col style="position: sticky; top: 170px;" class="mb-3" :xs="24" :sm="24" :md="9">
              <!-- Didn't use promotion gift -->
              <PotensialCoins
                ref="calculation"
                flag="checkout"
                :carts="carts"
                :pointUsed="pointUsed"
                :dataPercentage="dataTransactionType"
                @emitLocalUsedPoints="emitLocalUsedPoints"
                :grandTotal="grandTotal"
                :potensialPromotion="potensialPromotion"
              />
              <CalculationComponent ref="calculation" :pointUsed="pointUsed" :creditLimitUsage="creditLimitUsage"
                :carts="carts" :dataDiscount="dataDiscount" :dataTransactionType="dataTransactionType"
                @setVisibleModalConfirmCheckout="setVisibleModalConfirmCheckout"
                @handleCheckoutPage="onOpenPaymentModal" @emitLocalUsedPoints="emitLocalUsedPoints"
                :selectedPayment="selectedPayment" :selectedMixPayment="selectedMixPayment"
                :selectedShippingAddress="selectedShippingAddress" :depositReturn="depositReturn"
                @handleDepositReturn="handleDepositReturn" @handleGrandTotal="handleGrandTotal" @creditUsed="creditUsed"
                @handleGrandTotalBeforeCreditLimit="handleGrandTotalBeforeCreditLimit"
                @handleTempUsedPoints="handleTempUsedPoints" :grandTotal="grandTotal"
                :currentTotalCreditLimit="currentTotalCreditLimit" :selectedVoucherOnFaktur="selectedVoucherOnFaktur"
                @handleTotalPriceProduct="handleTotalPriceProduct" @handleTempCreditLimit="handleTempCreditLimit"
                :voucherOnFaktur="voucherOnFaktur" @emitSelectedVoucher="onSelectedVoucherOnFaktur"
                :selectedShippingMethod="selectedShippingMethod" :selectedBillingAddress="selectedBillingAddress"
                :selectedShippingType="selectedShippingType" :coinGift="coinGift" :selectedMethod="selectedMethod"
                :isInsurance="isInsurance" :insuranceCost="insuranceCost" @handleSkipPaymentAndCheckout="onSkipPaymentAndCheckout"
              />
            </a-col>
          </a-row>

          <a-row class="mb-3" v-if="!carts.length">
            <h1 class="mx-auto d-flex justify-content-center">
              {{ $t('purchase.checkout.cart_empty') }}
            </h1>
            <h5 class="mx-auto d-flex justify-content-center">
              {{ $t('purchase.checkout.select_product') }} <span class="ml-2" style="color: blue; cursor: pointer;"
                @click.prevent="() => $router.push({ path: '/purchase/catalogue' })"> {{ $t('purchase.checkout.here') }}
              </span>
            </h5>
          </a-row>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import ShippingComponent from '@/components/Purchase/Checkout/Shipping'
import DropshipperComponent from '@/components/Purchase/Checkout/Dropshipper'
import ShippingMethodComponent from '@/components/Purchase/Checkout/ShippingMethod'
import BillingComponent from '@/components/Purchase/Checkout/Billing'
import CartComponent from '@/components/Purchase/Checkout/Cart'
import CalculationComponent from '@/components/Purchase/Checkout/Calculation'
import { getIdWarehouse, joinWarehouseCart } from '@/utils/purchase'
import PotensialCoins from '@/components/Purchase/Checkout/PotensialCoins/index.vue'
import getPromotionPerProduct from '@/utils/discount/PerProduct'
import { getPromotionPerTransaction } from '@/utils/discount/PerTransaction'
import { v4 as uuidv4 } from 'uuid'
import ModalResetPayment from '@/views/purchase/modal-reset-payment.vue'
import ModalCheckoutError from '@/views/purchase/modal-checkout-error.vue'
import ModalCheckoutWaiting from '@/views/purchase/modal-checkout-waiting.vue'
import { mapGetters } from 'vuex'
import { uniq } from 'lodash'

export default {
  components: {
    ShippingComponent,
    DropshipperComponent,
    ShippingMethodComponent,
    BillingComponent,
    CartComponent,
    CalculationComponent,
    ModalResetPayment,
    ModalCheckoutError,
    ModalCheckoutWaiting,
    PotensialCoins,
  },
  data: function () {
    return {
      listAvailableShippingAddress: [],
      selectedShippingAddress: null,
      listAvailableBillingAddress: [],
      selectedBillingAddress: null,
      carts: [],
      cartTemp: null,
      paymentList: [],
      creditLimit: {},
      selectedMixPayment: null,
      selectedPayment: null,
      visibleModalConfirmCheckout: false,
      loading: false,
      sellerId: '',
      sum: 0,
      modalError: {
        visible: false,
        message: '',
        type: '',
      },
      localPointUsed: 0,
      creditLimitUsage: 0,
      businessId: '',
      customerId: '',
      dataDiscount: [],
      dataDiscountPromo: [],
      dataVoucher: [],
      dataPercentage: [],
      dataTransactionType: [],
      depositReturn: 0,
      depositReturnUsed: 0,
      grandTotal: 0,
      voucherOnFaktur: [],
      selectedVoucherOnFaktur: [],
      potensialPromotion: [],
      currentTotalCreditLimit: 0,
      currentTotalCoin: 0,
      currentTotalDepositReturn: 0,
      grandTotalBeforeCreditLimit: 0,
      promotions: {},
      showNotificationResetPayment: false,
      tempUsedPoints: 0,
      totalProduct: 0,
      tempCreditLimit: 0,
      selectedShippingMethod: null,
      idShippingMethod: null,
      currency: 'IDR',
      coinGift: 0,
      isDropshipper: false,
      dropShipping: {
        first_name: '',
        last_name: '',
        phone: '',
      },
      selectedShippingType: 'shipping',
      totalWeight: 0,
      defaultMetaShippingAddress: null,
      warehouseData: null,
      // Payment Component
      selectedMethod: '',
      selectedMixMethod: '',
      insuranceCost: 0,
      isInsurance: false,
      checkoutWaitingExpired: false,
      modalCheckoutWaiting: false,
      retries: 0,
      phoneOVO: this.$store.state.user.user_data.phone,
      promotionItems: [],
      isValidToPay: false,
    }
  },
  watch: {
    'selectedShippingMethod': {
      deep: true,
      immediate: true,
      handler: function (value) {
        let payload = {}
        if (this.selectedShippingType === 'shipping') {
          payload = {
            courier_id: value?.courier_id,
            courier_name: value?.name,
            service_code: value?.service?.code,
            service_type: value?.service?.type,
            service_name: value?.service?.name,
          }
        }
        this.$store.dispatch('purchase/POSTSHIPPINGMETHOD', {
          payload,
        })
          .then(({ data }) => {
            this.idShippingMethod = data.id
          })
          .catch(() => { })
      },
    },
    'selectedShippingType': {
      deep: true,
      immediate: true,
      handler: async function (value) {
        if (this.selectedShippingType === 'shipping' && this.isGuest) {
          this.selectedShippingAddress = null
          this.selectedBillingAddress = null
        }
        if (this.selectedShippingType === 'pickup' && this.isGuest) {
          this.selectedShippingAddress = this.defaultMetaShippingAddress
          this.selectedBillingAddress = null
        }
      },
    },
    '$store.state.meta.data': {
      deep: true,
      immediate: true,
      handler: async function (value) {
        const metaShippingAddress = value.find((obj) => obj.meta_name === 'contacts')
        const jsonShippingAddress = JSON.parse(metaShippingAddress?.meta_value)
        if (jsonShippingAddress) {
          const tempAddress = {
            ...jsonShippingAddress.address,
            address_line1: jsonShippingAddress?.address?.line1,
            phone: jsonShippingAddress?.phone,
            first_name: jsonShippingAddress?.address?.first_name,
            last_name: jsonShippingAddress?.address?.last_name,
          }
          this.defaultMetaShippingAddress = tempAddress
          if (this.selectedShippingType === 'pickup' && this.isGuest) {
            this.selectedShippingAddress = tempAddress
            this.selectedBillingAddress = null
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters('user', ['isGuest']),
    pointUsed() {
      return this.$store.state.loyalty.usedPoints
    },
    totalPriceProduct() {
      return this.$refs.calculation.totalPriceProduct
    },
    shippingCost() {
      return 0
    },
    promotion() {
      return 0
    },
    potensialCoin() {
      return this.$refs.calculation.totalPriceProduct
    },
    grossAmount() {
      return getPromotionPerTransaction(this.totalPriceProduct, this.dataDiscount) + this.shippingCost + this.insuranceCost
    },
    totalPrice() {
      return this.grossAmount - this.promotion
    },
  },
  methods: {
    handleCurrentTotalCreditLimit(value) {
      this.currentTotalCreditLimit = value
    },
    handleCurrentTotalCoin(value) {
      this.currentTotalCoin = value
    },
    emitLocalUsedPoints(val) {
      this.localPointUsed = val
    },
    emitSelectedShippingMethod(val) {
      this.selectedShippingMethod = val
      // if (val?.name === 'OWN') {
      //   this.selectedShippingType = 'pickup'
      // }
    },
    emitSelectedShippingType(val) {
      this.selectedShippingType = val
    },
    emitIsDropshipper(val) {
      this.isDropshipper = val
    },
    emitIsDropShipping(val) {
      this.dropShipping = val
    },
    handleDepositReturn(val) {
      this.depositReturnUsed = val
    },
    handleGrandTotal(val) {
      this.grandTotal = val
    },
    handleGrandTotalBeforeCreditLimit(val) {
      this.grandTotalBeforeCreditLimit = val
    },
    handleTempUsedPoints(val) {
      this.tempUsedPoints = val
    },
    handleTotalPriceProduct(val) {
      this.totalProduct = val
    },
    handleTempCreditLimit(val) {
      this.tempCreditLimit = val
    },
    fetchShippingAddress({ addr_key }) {
      this.$store.dispatch('purchase/GETSHIPPINGADDRESS', {
        channel_id: this.$store.state.user.user_data.channel_id,
        addr_key,
      })
        .then(({ data }) => {
          if (data && data.length && addr_key === 'last') {
            this.selectedShippingAddress = data[0]
          }

          if (data && data.length && addr_key === 'all') {
            this.listAvailableShippingAddress = data
          }

          if (!this.selectedShippingAddress) {
            this.$refs.shippingComponent.$data.isCreateNewAddress = true
          }
        })
        .catch(() => {
          this.$refs.shippingComponent.$data.isCreateNewAddress = true
        })
    },
    setSelectedShippingAddress(value) {
      this.selectedShippingAddress = value
      this.fetchShippingAddress({ addr_key: 'all' })
      this.selectedShippingMethod = null
      this.idShippingMethod = null
    },
    fetchBillingAddress({ addr_key }) {
      this.$store.dispatch('purchase/GETBILLINGADDRESS', {
        channel_id: this.$store.state.user.user_data.channel_id,
        addr_key,
      })
        .then(({ data }) => {
          if (data && data.length && addr_key === 'last') {
            this.selectedBillingAddress = data[0]
          }

          if (data && data.length && addr_key === 'all') {
            this.listAvailableBillingAddress = data
          }

          if (!this.selectedBillingAddress) {
            this.$refs.billingComponent.$data.isCreateNewAddress = true
          }
        })
        .catch(() => {
          this.$refs.billingComponent.$data.isCreateNewAddress = true
        })
    },
    setSelectedBillingAddress(value) {
      this.selectedBillingAddress = value
      this.fetchBillingAddress({ addr_key: 'all' })
    },
    fetchPaymentList() {
      this.$store.dispatch('purchase/GETPAYMENTMETHOD', {
        channel_id: this.$store.state.user.user_data.channel_id,
        seller_id: this.sellerId,
      })
        .then(({ data }) => {
          this.paymentList = data
          this.creditLimit = data.credit_limit
        })
    },
    getPromotion() {
      const warehouse_id = this.$route.query.warehouse_id ? this.$route.query.warehouse_id : this.cartTemp.warehouse_id
      const list = this.cartTemp.cart_list.filter((item) => item.warehouse_id === warehouse_id)
      const price = list.reduce((acc, current) => {
        return acc + current.promo.summary.finalPrice
      }, 0)
      const bodyPayload =
      {
        business_id: this.businessId,
        seller_id: this.sellerId,
        seller_area_ids: [this.$store.state.user.user_data.area_id],
        customer_id: this.$store.state.user.user_data.buyer_id,
        customer_category_id: this.$store.state.user.user_data.customer_category_id,
        items: {
          amount: price,
          detail_items: list.map(item => {
            return {
              category_id: item.category_id,
              catalog_id: item.item_id,
              qty: item.quantity,
              uom: item.uom,
            }
          }),
        },
      }
      this.$store.dispatch('purchase/GETPROMOTION', {
        payload: bodyPayload,
        channel_id: this.$store.state.user.user_data.channel_id,
      })
        .then(async ({ data }) => {
          const potensialPromotion = []
          const tempPromotionItems = []
          this.dataDiscount = data.discounts
          this.dataVoucher = data?.vouchers ?? []
          this.dataPercentage = this.dataDiscount.filter(v => { return v.gift[0].value_type === 'percentage' })
          this.dataTransactionType = this.dataDiscount.filter(v => { return v.type === 'transaction' })
          const cartMapping = this.carts[0].cart_list.map(v => {
            return {
              ...v,
              price: {
                ...v.price,
                selling_price: getPromotionPerProduct(v, this.dataDiscount),
              },
            }
          })

          this.coinGift = 0
          data.coins.forEach(dataCoin => {
            dataCoin.gift.forEach(dataGift => {
              potensialPromotion.push({
                ...dataGift,
                ...dataCoin,
              })
              this.coinGift += dataGift.value
            })
          })
          data.vouchers.forEach(dataVoucher => {
            dataVoucher.gift.forEach(dataGift => {
              potensialPromotion.push({
                ...dataGift,
                ...dataVoucher,
                name: dataVoucher.name,
              })
            })
          })

          this.promotions = {
            coins: data.coins,
            discounts: data.discounts,
            product: data.product,
            vouchers: data.vouchers,
          }

          this.carts[0].cart_list = cartMapping

          let listCatalogId = []
          data.product.forEach((obj) => {
            listCatalogId = uniq(listCatalogId.concat(obj.gift.map((obj2) => obj2.catalog_id)))
          })

          if (listCatalogId.length > 0) {
            this.$store.dispatch('product/GETPRODUCTDETAILBYCATALOG', {
              catalog_id: listCatalogId,
              channel_id: this.$store.state.app.channel_id,
            })
              .then((response) => {
                data.product.map((obj) => {
                  potensialPromotion.push({
                    ...obj,
                    gift: obj.gift.map((obj2) => {
                      return {
                        ...obj2,
                        catalog: response.find((obj3) => obj3.id === obj2.catalog_id),
                      }
                    }),
                  })
                })
              })
              .catch((err) => {
                console.error(err)
                this.$notification.error({
                  message: 'failure get detail product',
                })
              }).finally(() => {
                potensialPromotion.forEach((potensial) => {
                  if (potensial.gift_type === 'product') {
                    potensial.gift.forEach((dataGift) => {
                      tempPromotionItems.push({
                        promotion_id: potensial.promotion_id,
                        catalog_id: dataGift?.catalog.id,
                        catalog_sku: dataGift?.catalog.sku,
                        catalog_title: dataGift?.catalog.title,
                        uom: dataGift?.uom,
                        name: potensial.name,
                        quantity: dataGift.qty,
                        reference_catalog_id: potensial?.catalog?.id || null,
                        reference_catalog_sku: potensial?.catalog?.sku || null,
                        reference_catalog_title: potensial?.catalog?.title || null,
                        reference_uom: potensial.uom,
                      })
                    })
                  }
                })

                this.promotionItems = tempPromotionItems
                this.potensialPromotion = potensialPromotion
              })
          } else {
            this.potensialPromotion = potensialPromotion
          }
        })
        .catch((err) => {
          // const { data } = err.responsive
          this.$notification.error({
            message: 'Gagal memuat discount',
            description: err.response?.data?.message.substring(0, 100) || err.message,
          })
        })
    },
    getActiveVoucher() {
      const bodyPayload = {
        page: 1,
        limit: 50,
        status: 'UNUSED',
        business_id: this.$store.state.app.business_id,
        owner_id: this.$store.state.user.user_data.buyer_id,
      }
      this.$store.dispatch('purchase/GETACTIVEVOUCHER', {
        payload: bodyPayload,
        channel_id: this.$store.state.user.user_data.channel_id,
      })
        .then(async ({ data }) => {
          const tempArrVoucher = data?.map((obj) => {
            return {
              ...obj,
              checked: false,
            }
          })
          this.voucherOnFaktur = tempArrVoucher ?? []
        })
        .catch((err) => {
          this.$notification.error({
            message: 'Gagal memuat voucher',
            description: err.response?.data?.message.substring(0, 100) || err.message,
          })
        })
    },
    setSelectedPayment(value) {
      this.selectedPayment = value
    },
    setSelectedMixPayment(value) {
      this.selectedMixPayment = value
    },
    getBillingAddressDataForm(param) {
      const form = this.$refs.billingComponent.$refs.billingAddressForm.$data.form

      return form.getFieldValue(param)
    },
    getShippingAddressDataForm(param) {
      const form = this.$refs.shippingComponent.$refs.shippingAddressForm.$data.form

      return form.getFieldValue(param)
    },
    getCountryLabel(value, addressType) {
      if (addressType === 'billing') {
        const findData = this.$refs.billingComponent.$refs.billingAddressForm.$data.countryList.find(item => +item.value === +value)
        return findData ? findData.label : undefined
      }

      if (addressType === 'shipping') {
        const findData = this.$refs.shippingComponent.$refs.shippingAddressForm.$data.countryList.find(item => +item.value === +value)
        return findData ? findData.label : undefined
      }
    },
    getCiyId(label, addressType) {
      if (addressType === 'billing') {
        const findData = this.$refs.billingComponent.$refs.billingAddressForm.$data.cityList.find(item => item.label === label)
        return findData ? findData.value : undefined
      }

      if (addressType === 'shipping') {
        const findData = this.$refs.shippingComponent.$refs.shippingAddressForm.$data.cityList.find(item => item.label === label)
        return findData ? findData.value : undefined
      }
    },
    getAreaId(label, addressType) {
      if (addressType === 'billing') {
        const findData = this.$refs.billingComponent.$refs.billingAddressForm.$data.areaList.find(item => item.label === label)
        return findData ? findData.value : undefined
      }

      if (addressType === 'shipping') {
        const findData = this.$refs.shippingComponent.$refs.shippingAddressForm.$data.areaList.find(item => item.label === label)
        return findData ? findData.value : undefined
      }
    },
    setVisibleModalConfirmCheckout(value) {
      if (this.$refs.shippingComponent.$data.isCreateNewAddress) {
        const element = document.getElementById('shippingComponent')
        element.scrollIntoView({
          behavior: 'smooth',
        })

        return this.$notification.warning({
          message: this.$t('purchase.checkout.confirmShipping'),
        })
      }

      if (this.$refs.billingComponent.$data.isCreateNewAddress) {
        const element = document.getElementById('billingComponent')
        element.scrollIntoView({
          behavior: 'smooth',
        })

        return this.$notification.warning({
          message: this.$t('purchase.checkout.confirmBilling'),
        })
      }

      /** Checking selected payment */
      if (!this.selectedPayment && value) {
        const element = document.getElementById('cartComponent')
        element.scrollIntoView({
          behavior: 'smooth',
        })

        return this.$notification.warning({
          message: this.$t('purchase.checkout.confirmPaymentMethods'),
        })
      }

      if (!value) {
        const element = document.getElementById('checkoutTitle')
        element.scrollIntoView({
          behavior: 'smooth',
        })
      }
      this.visibleModalConfirmCheckout = value
    },
    creditUsed(val) {
      this.creditLimitUsage = val ?? 0
    },
    // will be used later
    retry(data, ms = 2000) {
      this.retries = this.retries + 1
      const maxRetries = process.env.VUE_APP_CHECKOUT_IDLE ?? 7
      const varData = data
      return new Promise((resolve, reject) => {
        this.getDetailOrder(data.id)
          .then(({ data }) => {
            const status = data.order_state.toLowerCase()
            if (this.retries < maxRetries) {
              if (status === 'created') {
                setTimeout(() => {
                  this.retry(varData)
                }, ms)
              } else if (status === 'rejected') {
                this.modalCheckoutWaiting = false
                this.checkoutWaitingExpired = false
                this.modalError = {
                  visible: true,
                  message: data.reason,
                  type: 'rejected',
                }
              } else if (status === 'submitted' && data.payment_data.term.toUpperCase() === 'CAD') {
                this.updateCartWhenSuccessCheckout()
                this.$router.push({ path: `/order-confirmation/full-term-of-payment/${varData.id}` })
              } else if (status === 'submitted' && data.payment_data.term.toUpperCase() === 'COD') {
                this.updateCartWhenSuccessCheckout()
                this.$router.push({ path: `/order-confirmation/cash-on-delivery/${varData.id}` })
              } else {
                this.updateCartWhenSuccessCheckout()
                this.modalCheckoutWaiting = false
                this.checkoutWaitingExpired = false
                if (this.isGuest) {
                  window.localStorage.setItem('session_id', uuidv4())
                }
                this.$router.push({ path: `/purchase/how-to-pay/${varData.id}` })
                resolve(data)
              }
            } else {
              this.modalCheckoutWaiting = true
              this.checkoutWaitingExpired = true
            }
          }).catch((err) => {
            this.modalCheckoutWaiting = true
            this.checkoutWaitingExpired = true
            reject(err)
          })
      })
    },
    async getDetailOrder(order_id) {
      return await this.$store.dispatch('purchase/GETDETAIL', { order_id, channel_id: this.$store.state.user.user_data.channel_id })
    },
    onOpenPaymentModal(valid) {
      this.isValidToPay = valid
      if (valid === false) {
        this.selectedMethod = null
        this.creditLimitUsage = 0
        this.selectedMixMethod = null
      }
    },
    onSkipPaymentAndCheckout() {
      const paymentListFind = this.paymentList?.payment_method.find((obj) => obj.payment_term === 'CBD')
      if (paymentListFind?.payment_method?.length > 0) {
        const defaultSelectedPayment = paymentListFind.payment_method.at(0)
        this.selectedPayment = {
          ...defaultSelectedPayment,
          term: 'CBD',
          payment_type: 'VA',
          method_id: defaultSelectedPayment.id,
          pg_channel_id: defaultSelectedPayment.pg_channels_id,
          pg_channels_id: undefined,
        }
        this.onValidateCheckout()
      } else {
        console.error('CBD - VA Payment term not exist, for default payment with 0 total amount')
      }
    },
    onValidateCheckout() {
      this.modalCheckoutWaiting = true
      if (this.isGuest) {
        let guestData
        let guestShippingAddress
        let guestBillingAddress
        this.$store.dispatch('user/GUESTREGISTERBUYERID', {
          email: this.selectedBillingAddress?.email,
          phone: this.selectedBillingAddress?.phone,
          code: '',
          tags: '',
          store_name: `${this.selectedBillingAddress?.first_name} ${this.selectedBillingAddress?.last_name}`,
          store_enable: 1,
          key: '',
          type: 'GUEST',
          npwp: 0,
          owner_name: `${this.selectedBillingAddress?.first_name} ${this.selectedBillingAddress?.last_name}`,
          level_model_id: 1,
          channel_id: this.$store.state.app.channel_id,
          address: {
            line1: this.selectedBillingAddress?.address_line1,
            line2: this.selectedBillingAddress?.address_line2,
            country_id: this.selectedBillingAddress?.country_id,
            area_id: this.selectedBillingAddress?.area_id,
            city_id: this.selectedBillingAddress?.city_id,
            postcode: this.selectedBillingAddress?.postal_code,
            longitude: null,
            latitude: null,
            map_detail: null,
          },
        })
          .then(({ data }) => {
            guestData = data
            this.$store.dispatch('purchase/ADDSHIPPINGADDRESS', {
              ...this.selectedShippingAddress,
              channel_id: this.$store.state.user.user_data.channel_id,
              guest_buyer_id: guestData?.id,
            })
              .then(({ data }) => {
                guestShippingAddress = data
                return this.$store.dispatch('purchase/ADDBILLINGADDRESS', {
                  ...this.selectedBillingAddress,
                  channel_id: this.$store.state.user.user_data.channel_id,
                  guest_buyer_id: guestData?.id,
                })
              })
              .then(({ data }) => {
                guestBillingAddress = data
              })
              .catch((err) => {
                console.error(err)
              })
              .finally(() => {
                this.onCheckout(guestData, guestBillingAddress, guestShippingAddress)
              })
          }).catch((err) => {
            console.error(err)
          })
      } else {
        this.onCheckout(null)
      }
    },
    onCheckout(guestBuyerData, guestBillingAddress, guestShippingAddress) {
      const customer_data = {
        id: guestBuyerData ? guestBuyerData.id : this.$store.state.user.user_data.buyer_id,
        entity_name: guestBuyerData ? guestBuyerData.store_name : this.$store.state.user.user_data.store_name,
        first_name: this.$store.state.user.user_data.first_name,
        last_name: this.$store.state.user.user_data.last_name,
        email: guestBuyerData ? guestBuyerData.email : this.$store.state.user.user_data.email,
        phone: this.selectedPayment?.method_id === 206 ? this.phoneOVO : (guestBuyerData ? guestBuyerData.phone : (this.$store.state.user.user_data.phone || this.selectedBillingAddress.phone)),
      }
      const transaction_data = {
        payment_method: this.selectedPayment?.method,
        sub_total: (this.totalPriceProduct - this.localPointUsed),
        shipping_cost: this.shippingCost, // refer to shipment_data shipping_cost
        insurance_cost: this.insuranceCost, // refer to shipment_data insurance_cost
        gross_amount: this.grossAmount, // gross_amout sub_total + shipping cost + insurance
        total: this.totalPrice, // total setelah dikurangi promotion * apakah dikurangi produknya atau setelah ditambah sipping cost dll
        currency: 'IDR',
        loyalty_usage: this.localPointUsed > 0 ? this.localPointUsed : null,
        deposit: this.depositReturnUsed,
      }

      const billingAddress = guestBuyerData ? guestBillingAddress : this.selectedBillingAddress
      const shippingAddress = guestBuyerData ? guestShippingAddress : this.selectedShippingAddress

      const billing_data = billingAddress ? {
        id: billingAddress.id,
        first_name: billingAddress.first_name,
        last_name: billingAddress.last_name,
        phone: billingAddress.phone,
        email: guestBuyerData ? this.selectedBillingAddress?.email : (this.selectedBillingAddress.email || this.$store.state.user.user_data.email),
        address: {
          line1: billingAddress.address_line1,
          line2: billingAddress.address_line2 !== '' ? billingAddress.address_line2 : null,
          postal_code: billingAddress.postal_code,
          country: billingAddress.country,
          province: billingAddress.province,
          city: billingAddress.city,
          district: billingAddress.district,
          sub_district: billingAddress.sub_district,
          country_id: +billingAddress.country_id,
          city_id: +billingAddress.city_id,
          sub_district_id: +billingAddress.area_id,
        },
      } : null
      const destination_data = {
        id: (shippingAddress && shippingAddress.id) || billingAddress.id,
        first_name: shippingAddress?.first_name || billingAddress?.first_name,
        last_name: shippingAddress?.last_name || billingAddress?.last_name,
        phone: shippingAddress?.phone || billingAddress?.phone,
        email: (guestBuyerData ? this.selectedShippingAddress?.email : (this.selectedShippingAddress?.email || this.$store.state.user.user_data.email)) || billingAddress?.email,
        address: {
          line1: this.selectedShippingType === 'shipping' ? shippingAddress.address_line1 : (this.warehouseData.address.line1 || null),
          line2: this.selectedShippingType === 'shipping' ? (shippingAddress.address_line2 !== '' ? shippingAddress.address_line2 : null) : (this.warehouseData.address.line2 || null),
          postal_code: this.selectedShippingType === 'shipping' ? shippingAddress.postal_code : (this.warehouseData.address.post_code || null),
          country: this.selectedShippingType === 'shipping' ? shippingAddress.country : (this.warehouseData.address.country || null),
          province: this.selectedShippingType === 'shipping' ? shippingAddress.province : (this.warehouseData.address.province || null),
          city: this.selectedShippingType === 'shipping' ? shippingAddress.city : (this.warehouseData.address.city || null),
          district: this.selectedShippingType === 'shipping' ? shippingAddress.district : (this.warehouseData.address.district || null),
          sub_district: this.selectedShippingType === 'shipping' ? shippingAddress.sub_district : (this.warehouseData.address.subDistrict || null),
          country_id: this.selectedShippingType === 'shipping' ? +shippingAddress.country_id : (this.warehouseData.address.country_id || null),
          city_id: this.selectedShippingType === 'shipping' ? +shippingAddress.city_id : (this.warehouseData.address.city_id || null),
          sub_district_id: this.selectedShippingType === 'shipping' ? +shippingAddress.area_id : (this.warehouseData.address.area_id || null),
        },
      }
      const shipping_data = {
        id: this.idShippingMethod,
        cost_type: null,
        courier: this.selectedShippingMethod?.name || 'SPU',
        service_code: this.selectedShippingMethod?.service.code || 'SPU',
        service_type: this.selectedShippingMethod?.service.type || '',
        service_name: this.selectedShippingMethod?.service.name || '',
        shipping_cost: {
          value: this.shippingCost + (this.selectedShippingMethod?.price || 0),
          currency: 'IDR',
        },
        insurance_cost: {
          value: this.isInsurance ? this.insuranceCost : 0,
          currency: 'IDR',
        },
        is_cod: null,
        cod_calue: null,
        remarks: null,
        shipping_label_url: null,
        awb: null,
        booking_no: null,
      }
      let payment = {
        ...this.selectedPayment,
        gross_amount: {
          value: this.totalPrice,
          currency: 'IDR',
        },
      }
      if (this.selectedPayment?.term === 'CAD') {
        payment = {
          ...payment,
          credit_limit: this.creditLimit.balance,
          term_days: null,
          gross_amount: {
            value: this.totalPrice,
            currency: 'IDR',
          },
        }
      }
      if (this.selectedMixPayment) {
        payment = {
          ...payment,
          bank: this.selectedMixPayment.bank,
          number: this.selectedMixPayment.number,
          payment_name: this.selectedMixPayment.payment_name,
          pg_channel_id: this.selectedMixPayment.pg_channel_id,
          mix_payment_method_id: this.selectedMixPayment.method_id,
          payment_type: this.selectedMixPayment.payment_type,
        }
      }
      const cart_id = this.cartTemp.id
      const warehouse_id = this.$route.query.warehouse_id
      const seller_id = this.carts[0].seller_id // dari warehouse besar
      const po_number = this.$refs.cartComponent.$data.poNumber || ''
      const carts = this.cartTemp.cart_list.map(item => {
        return {
          id: item.id,
          item_id: item.item_id,
          catalog: {
            sku: item.catalog_sku,
            product_name: item.catalog_title,
            uom: item.uom,
          },
          price: {
            ...item?.price, // kurang data
            selling_price: getPromotionPerProduct(item, this.dataDiscount),
          },
          image_url: item.showimg_url,
          qty: item.quantity,
          status: 'OPEN',
        }
      })

      //   {
      //     customer_data,
      //     transaction_data,
      //     billing_data: billingAddress ? billing_data : destination_data,
      //     destination_data,
      //     shipping_data,
      //     promotions: {
      //       ...this.promotions,
      //       vouchers_used: this.selectedVoucherOnFaktur.map((obj) => {
      //         return {
      //           id: obj.id,
      //           type: obj.promotion_type,
      //           value: obj.value,
      //           max_value: obj.max_value,
      //           value_type: obj.value_type,
      //           is_combine: obj.is_combine,
      //           promotion_id: obj.promotion_id,
      //           score: obj.score,
      //           business_id: obj.business_id,
      //           channel_id: obj.channel_id,
      //           owner_id: obj.owner_id,
      //           name: obj.name,
      //         }
      //       }),
      //     },
      //     promotion_items: this.promotionItems,
      //     payment,
      //     cart_id,
      //     warehouse_id,
      //     seller_id,
      //     po_number,
      //     is_self_pickup: this.selectedShippingType === 'pickup',
      //     carts,
      //     channel_id: this.$store.state.user.user_data.channel_id,
      //     pg_channel_id: payment.payment_type === 'VA' ? payment.pg_channel_id : null,
      //     dropshipper: !this.isDropshipper ? null : {
      //       first_name: this.dropShipping.first_name,
      //       last_name: this.dropShipping.last_name,
      //       name: `${this.dropShipping.first_name} ${this.dropShipping.last_name}`,
      //       phone: this.dropShipping.phone,
      //     },
      //     guest_buyer_id: guestBuyerData,
      //   },
      // )
      // this.modalCheckoutWaiting = false
      // this.checkoutWaitingExpired = false

      this.$store.dispatch('purchase/CHECKOUT', {
        customer_data,
        transaction_data,
        billing_data: billingAddress ? billing_data : destination_data,
        destination_data,
        shipping_data,
        promotions: {
          ...this.promotions,
          vouchers_used: this.selectedVoucherOnFaktur.map((obj) => {
            return {
              id: obj.id,
              type: obj.promotion_type,
              value: obj.value,
              max_value: obj.max_value,
              value_type: obj.value_type,
              is_combine: obj.is_combine,
              promotion_id: obj.promotion_id,
              score: obj.score,
              business_id: obj.business_id,
              channel_id: obj.channel_id,
              owner_id: obj.owner_id,
              name: obj.name,
            }
          }),
        },
        promotion_items: this.promotionItems,
        payment,
        cart_id,
        warehouse_id,
        seller_id,
        po_number,
        is_self_pickup: this.selectedShippingType === 'pickup',
        carts,
        channel_id: this.$store.state.user.user_data.channel_id,
        pg_channel_id: payment.payment_type === 'VA' ? payment.pg_channel_id : null,
        dropshipper: !this.isDropshipper ? null : {
          first_name: this.dropShipping.first_name,
          last_name: this.dropShipping.last_name,
          name: `${this.dropShipping.first_name} ${this.dropShipping.last_name}`,
          phone: this.dropShipping.phone,
        },
        guest_buyer_id: guestBuyerData,
      })
        .then(async ({ data }) => {
          this.retry(data)
        })
        .catch((err) => {
          const { data } = err.response
          this.modalError = {
            visible: true,
            message: this.$t(data.message_code) || data.message,
            // message: data &&
            //   data.message === 'Out of stock item' ? 'Stok sudah habis, silahkan hapus produk pada halaman keranjang'
            //   :  'Stok tidak cukup, silahkan ubah kuanti produk pada halaman keranjang',
            type: 'error',
          }
          this.modalCheckoutWaiting = false
          this.checkoutWaitingExpired = false
        })
    },
    updateCartWhenSuccessCheckout() {
      this.$store.dispatch(this.isGuest ? 'purchase/GETCARTGUEST' : 'purchase/GETCART', {
        channel_id: this.$store.state.user.user_data.channel_id,
      })
        .catch((err) => console.error(err))
        .finally(() => {
        })
    },
    fetchBalanceReturn(seller_id) {
      this.loading = true
      this.$store.dispatch('retur/GETBALANCERETURN', {
        seller_id,
      })
        .then((data) => {
          const dataBalance = data.data.find((data) => data.seller_id === seller_id)
          this.depositReturn = dataBalance.balance
        })
    },
    onSelectedVoucherOnFaktur(val) {
      // this.showNotificationResetPayment = true
      this.selectedVoucherOnFaktur = val.filter((obj) => obj.checked === true)
      // if (this.selectedVoucherOnFaktur) {
      //   this.selectedMethod = null
      // }
      // this.pointUsed = 0
      // this.creditLimitUsage = 0
      // this.localPointUsed = 0
    },
    getCatalogShipping() {
      const promises = []
      const listCatalogId = this.cartTemp.cart_list.map((data) => data.product_id)
      listCatalogId.forEach((product_id) => {
        promises.push(
          this.$store.dispatch('product/GETPRODUCTSHIPPING', {
            channel_id: this.$store.state.user.channel_id,
            business_id: this.$store.state.app.business_id,
            product_id,
          }),
        )
      })
      Promise.all(promises)
        .then((response) => {
          const catalogUnit = []
          let totalWeight = 0
          response.forEach((responseData) => {
            responseData.data.forEach((dataShipping) => {
              catalogUnit.push(dataShipping)
            })
          })
          this.cartTemp.cart_list.forEach((cart) => {
            const shippingUnit = catalogUnit.find((catalog) => catalog.productId === cart.product_id)
            if (shippingUnit.weightUnit === 'kg') {
              totalWeight += (shippingUnit.weight * 1000) * cart.quantity
            } else {
              totalWeight += shippingUnit.weight * cart.quantity
            }
          })
          this.totalWeight = totalWeight
        })
        .catch((err) => {
          console.error({ err })
        })
    },
    getProductPromo() {
      const listCatalogId = []
      this.cartTemp.cart_list.forEach((data) => {
        listCatalogId.push(data.item_id)
      })
      this.$store
        .dispatch('product/GETDISCOUNT', {
          channel_id: this.$store.state.app.channel_id,
          business_id: this.$store.state.app.business_id,
          catalog_ids: listCatalogId.toString(),
          customer_category_id: this.$store.state.user.user_data.customer_category_id,
        })
        .then((response) => {
          this.dataDiscountPromo = response.data
          this.handleDiscount()
        })
        .catch((err) => {
          console.error({ err })
        })
    },
    handleDiscount() {
      const newCartList = []
      this.cartTemp.cart_list.map((data) => {
        let summary = {
          price: data.quantity * data?.price.selling_price,
          finalPrice: data.quantity * data?.price.selling_price,
          discountType: null,
          discount: 0,
          discountValue: 0,
          discountPerPrice: 0,
        }
        const getPromo = this.dataDiscountPromo.find((discount) => discount.catalog_id === data.item_id)
        if (getPromo) {
          getPromo.promotions.forEach((promotion) => {
            promotion.gift.forEach((gift) => {
              if (gift.value_type === 'percentage') {
                let summaryDiscount = summary?.price * (gift.value / 100)
                if (summaryDiscount > gift.max_value) {
                  summaryDiscount = gift.max_value
                }
                let discountValuePerPrice = data?.price.selling_price * (gift.value / 100)
                if (discountValuePerPrice > gift.max_value) {
                  discountValuePerPrice = gift.max_value
                }
                const discountPerPrice = data?.price.selling_price - discountValuePerPrice
                summary = {
                  ...summary,
                  finalPrice: discountPerPrice * data?.quantity,
                  discountType: 'percentage',
                  discount: gift.value,
                  discountValue: summaryDiscount,
                  discountPerPrice,
                }
              } else {
                const discountPerPrice = data?.price.selling_price - (gift.value || 0)
                summary = {
                  ...summary,
                  finalPrice: discountPerPrice * data?.quantity,
                  discountType: 'fixed',
                  discount: gift.value,
                  discountValue: gift.value,
                  discountPerPrice,
                }
              }
            })
          })
        }
        newCartList.push({
          ...data,
          promo: {
            ...getPromo,
            summary,
          },
        })
      })

      this.cartTemp = {
        ...this.cartTemp,
        cart_list: newCartList,
      }
      this.getPromotion()
    },
  },
  mounted() {
    this.loading = true
    this.getActiveVoucher()
    this.$store.dispatch(this.isGuest ? 'purchase/GETCARTGUEST' : 'purchase/GETCART', {
      channel_id: this.$store.state.user.user_data.channel_id,
    })
      .then((response) => {
        const data = response.data
        if (data && data.id && Array.isArray(data.cart_list) && data.cart_list.length) {
          this.businessId = response.business_id
          this.cartTemp = { ...data }
          const warehouse_ids = this.$route.query.warehouse_id ? [this.$route.query.warehouse_id] : getIdWarehouse(this.cartTemp.cart_list).length ? getIdWarehouse(this.cartTemp.cart_list) : []
          this.$router.push({ query: { warehouse_id: warehouse_ids[0] } })
          return this.$store.dispatch('warehouse/GETWAREHOUSELIST', { id: warehouse_ids })
        }
      })
      .then(async ({ data }) => {
        this.carts = joinWarehouseCart(data, this.cartTemp.cart_list)
        this.sellerId = data[0].seller_id
        this.customerId = data[0].id
        this.warehouseData = data[0]
        this.fetchBalanceReturn(data[0].seller_id)
        if (!this.isGuest) {
          this.fetchShippingAddress({ addr_key: 'last' })
          this.fetchBillingAddress({ addr_key: 'last' })
        }
        this.fetchPaymentList()
        this.getProductPromo()
        this.getCatalogShipping()
      })
      .catch((err) => console.error(err))
      .finally(() => {
        this.loading = false
      })
  },
}
</script>

<style lang="scss">
.checkoutTitle {
  margin-bottom: 1rem;

  @media (max-width:600px) {
    margin-bottom: 0.5rem;
  }
}

.checkout-title {
  @media (max-width:600px) {
    font-size: 18px;
  }
}

.checkoutTitle {
  margin-bottom: 1rem;

  @media (max-width:600px) {
    margin-bottom: 0.2rem;
  }
}

.checkout-title {
  @media (max-width:600px) {
    font-size: 18px;
  }
}

.checkout {
  &-icon {
    background: var(--biz-brand-base) !important;
    padding: 6px 10px;
    border-radius: 50%;
  }
}

.btn-cart {
  // height: 33px;
  background: var(--biz-accent-status-3);
  border-radius: 5px;
  color: var(--biz-brand-base);
  cursor: pointer;
  border: none;
  width: 275px;
  padding: 5px;
}

.email-main-title {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0 10px 0;
}
.rotate-img {
  animation: rotation 1s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
