<template>
  <svg width="30" height="41" viewBox="0 0 30 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M27.4854 0.7849C27.3217 0.566666 27.0801 0.419959 26.8109 0.375298C26.5417 0.330637 26.2656 0.391462 26.0401 0.545118C25.8146 0.698774 25.6571 0.933432 25.6002 1.20027C25.5433 1.46711 25.5915 1.7456 25.7348 1.97778C28.5183 6.07542 26.301 10.2868 24.4051 12.7322L23.0744 11.6121C22.6273 11.2367 21.8015 11.0462 21.2376 11.19L15.8471 12.6598C15.1931 12.8563 14.6044 13.2259 14.1433 13.7295L1.22763 29.1393C0.927458 29.4986 0.781926 29.9623 0.822895 30.4286C0.863863 30.895 1.08799 31.3262 1.44622 31.6276L11.4432 40.0291C12.1912 40.6541 13.0314 40.3292 13.6591 39.5841L26.5743 24.1726C26.9447 23.7269 27.2861 22.888 27.3294 22.305L27.5926 16.5293C27.6364 15.9479 27.3058 15.1676 26.8586 14.7922L26.048 14.1096C28.5879 10.8041 30.7463 5.58729 27.4854 0.7849ZM24.3298 18.6063C24.0918 18.8898 23.8002 19.1237 23.4717 19.2945C23.1433 19.4653 22.7844 19.5698 22.4156 19.6019C22.0467 19.634 21.6752 19.5932 21.3222 19.4817C20.9691 19.3702 20.6416 19.1902 20.3581 18.9521C19.7844 18.4703 19.4253 17.7805 19.3597 17.0343C19.2941 16.288 19.5273 15.5461 20.0083 14.9715C20.3855 14.5218 20.8938 14.2012 21.4621 14.0544C22.0305 13.9076 22.6305 13.9421 23.1783 14.1529C22.7922 14.5539 22.5191 14.8012 22.4667 14.8425C22.2836 14.9987 22.1591 15.2125 22.1135 15.4488C22.0679 15.6851 22.1039 15.9298 22.2156 16.143C22.3273 16.3561 22.5081 16.525 22.7284 16.6219C22.9487 16.7188 23.1954 16.7381 23.428 16.6765C23.5736 16.6375 23.7135 16.5637 23.8364 16.46C24.1231 16.2163 24.4257 15.9375 24.7346 15.6207C24.9552 16.1059 25.0342 16.6436 24.9626 17.1718C24.891 17.7 24.6716 18.1972 24.3298 18.6063Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'voucher-fixed',
}
</script>
